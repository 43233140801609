import classNames from 'classnames'
import MessageStatus from './MessageStatus'
import './MessageMeta.css'

export default function MessageMeta(props) {
  const { className, isOutgoing, date, status, isGroupChat } = props

  if (isGroupChat && ['isSend', 'isRead'].indexOf(status) !== -1) {
    return (
      <div className={classNames('meta', className)}>
        {/* <span>&ensp;</span> */}
        <span>{new Date(date).format('hh:mm')}</span>
      </div>
    )
  } else
    return (
      <div className={classNames('meta', className)}>
        {/* <span>&ensp;</span> */}
        <span>{new Date(date).format('hh:mm')}</span>
        {isOutgoing && <MessageStatus status={status} />}
      </div>
    )
}
