import { List, Toast, Input, Avatar, Button, Dialog, Tag, Space, Divider } from 'antd-mobile'
import { useEffect, useRef, useState } from 'react'
import { setRemarkNameApi, getUserLikeLimitApi, addUserLikesApi, reportBlockUserApi } from '@/services/user'
import './UserProfile.css'

const UserProfile = (props) => {
  const {
    user,
    hideRemark,
    updateUserInfoCache,
    chatid,
    showOpt,
    quitChat,
    isShowRematch,
    onRematch,
    createChatByMatch,
  } = props

  const isRobot = user.user_type === 'robot'

  const textColor = 'var(--theme-text-color)'
  const listItemStyle = { textDecoration: 'none', lineHeight: '20px', color: textColor }

  const [remarkName, setRemarkName] = useState(user.remark_name || '')

  const [likes, setLikes] = useState(user.likes || 0)
  const likesRef = useRef(0)
  const likesLimitRef = useRef({ isSatisfy: true, remainCount: 10 })

  const genderMap = {
    male: '男',
    female: '女',
    keep_secret: '保密',
  }
  const genderColorMap = {
    male: '#4eb1ff',
    female: '#ff4ebb',
  }

  async function handleSaveRemarkName() {
    const { code } = await setRemarkNameApi(user.id, remarkName)
    if (code === 200) {
      updateUserInfoCache(user.id, { remark_name: remarkName })
      Toast.show({ icon: 'success', content: '保存成功' })
    }
  }

  useEffect(() => {
    setLikes(user.likes)
  }, [user])

  useEffect(() => {
    // 获取能否点赞，以及剩余点赞次数
    async function fetchData() {
      const { code, data } = await getUserLikeLimitApi(user.id, chatid)
      if (code === 200) {
        likesLimitRef.current = data
      }
    }
    if (showOpt && !isShowRematch) {
      fetchData()
    }
    return () => {
      // 更新点赞数
      if (likesRef.current) {
        addUserLikesApi(user.id, chatid, likesRef.current)
      }
    }
  }, [])

  function handleClickLike() {
    const { isSatisfy, remainCount } = likesLimitRef.current
    if (!isSatisfy) {
      Toast.show({ icon: 'fail', content: '聊天内容过少，无法点赞' })
      return
    }
    if (likesRef.current + 1 > remainCount) {
      Toast.show({ content: '您最多可为Ta点赞10次哦' })
      return
    }
    likesRef.current = likesRef.current + 1
    setLikes(likes + 1)
  }

  async function handleAction(node) {
    if (node.key !== 'cancel') {
      const { code, msg } = await reportBlockUserApi(user.id, chatid, node.key)
      if (code === 200) {
        Toast.show({ icon: 'success', content: msg })
        quitChat(chatid)()
      } else {
        Toast.show({ icon: 'fail', content: msg })
      }
    }
  }

  async function handleClickReport() {
    await Dialog.show({
      title: '举报并拉黑对方？',
      closeOnAction: true,
      closeOnMaskClick: true,
      content: '请选择以下举报类型，感谢您的积极反馈。恶意举报将遭到系统封禁',
      onAction: handleAction,
      actions: [
        [
          {
            key: 'ylzp',
            text: '引流诈骗',
          },
          {
            key: 'zzmg',
            text: '政治敏感',
          },
        ],
        [
          {
            key: 'wrmm',
            text: '侮辱谩骂',
          },
          {
            key: 'dssq',
            text: '低俗色情',
          },
        ],
        [
          {
            key: 'eysp',
            text: '恶意刷屏',
          },
          {
            key: 'others',
            text: '其他违法',
          },
        ],
        {
          key: 'cancel',
          text: '取消举报',
        },
      ],
    })
  }

  function createChat() {
    createChatByMatch()
  }

  return (
    <>
      <div style={{ display: 'flex', padding: '0px 10px 6px 10px', textAlign: 'center' }}>
        <Avatar src={user.avatar} style={{ width: 80, height: 80, marginBottom: '0px' }} />

        <div style={{ marginLeft: '10px' }}>
          <span
            style={{
              color: 'var(--theme-text-color)',
              lineHeight: '21px',
              fontSize: '18px',
              fontWeight: 'bold',
            }}>
            {user.nickname}
          </span>
        </div>
      </div>

      {(user.tags || []).length > 0 && (
        <div style={{ padding: '0px 10px 0px 10px' }} className="tags-wrapper">
          <Space wrap style={{ '--gap': '1px' }}>
            {(user.tags || []).map((e) => {
              return (
                <Tag key={e} round color="#2db7f5" style={{ fontSize: 12 }}>
                  {e}
                </Tag>
              )
            })}
          </Space>
        </div>
      )}

      <div style={{ textAlign: 'center', marginTop: '6px' }}>
        {(user.is_green || user.is_english) && (
          <div style={{ fontSize: 10 }}>
            对方已开启{user.is_green && <span style={{ fontWeight: 'bold', color: 'green' }}>&nbsp;绿色</span>}
            {user.is_english && <span style={{ fontWeight: 'bold', color: '#009dff' }}>&nbsp;英语</span>}
            &nbsp;模式
          </div>
        )}
      </div>

      <List style={{ color: textColor, marginTop: 4 }}>
        {!isRobot ? (
          <>
            <List.Item
              style={listItemStyle}
              extra={
                <span>
                  <span style={{ fontWeight: 'bold', color: genderColorMap[user.gender] }}>
                    {genderMap[user.gender]}
                  </span>
                  {user.is_confirm && <span style={{ color: 'red' }}>&nbsp;&nbsp;已认证</span>}
                </span>
              }>
              <span>性别</span>
            </List.Item>

            <List.Item style={listItemStyle} extra={<span>{user.age}</span>}>
              <span>年龄</span>
            </List.Item>

            <List.Item style={listItemStyle} extra={<span>{user.location}</span>}>
              <span>城市</span>
            </List.Item>

            <List.Item style={listItemStyle} extra={<span style={{ fontWeight: 'bold' }}>{likes}</span>}>
              <span>魅力值</span>
            </List.Item>
          </>
        ) : (
          <>
            <List.Item style={listItemStyle} extra={<span>{user.description}</span>}>
              <span>介绍</span>
            </List.Item>
            {/* <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                margin: '30px 0px 20px 0px',
                lineHeight: '23px',
              }}>
              <Button style={{ padding: '6px 24px' }} color="primary" onClick={handleClickLike}>
                点赞
              </Button>
            </div> */}
          </>
        )}

        {!hideRemark && !isRobot && (
          <List.Item
            style={listItemStyle}
            extra={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  value={remarkName}
                  onChange={(val) => {
                    setRemarkName(val)
                  }}
                  style={{ '--text-align': 'right' }}
                  maxLength={10}
                  placeholder="请输入备注"
                />
                <Button
                  size="mini"
                  color="primary"
                  fill="none"
                  style={{ padding: '0px', width: '37px', userSelect: 'none', marginLeft: 4 }}
                  onClick={handleSaveRemarkName}>
                  保存
                </Button>
              </div>
            }>
            <span>备注</span>
          </List.Item>
        )}
      </List>

      {showOpt && !isRobot && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            marginTop: '10px',
            lineHeight: '23px',
          }}>
          {!isShowRematch && (
            <Button style={{ padding: 0, marginLeft: 12 }} color="primary" fill="none" onClick={handleClickLike}>
              点赞
            </Button>
          )}

          {isShowRematch && (
            <Button color="primary" onClick={onRematch}>
              重新匹配
            </Button>
          )}

          {isShowRematch && (
            <Button color="success" onClick={createChat}>
              开始聊天
            </Button>
          )}

          {!isShowRematch && (
            <Button style={{ padding: 0, marginRight: 12 }} color="danger" fill="none" onClick={handleClickReport}>
              举报
            </Button>
          )}
        </div>
      )}
    </>
  )
}

export default UserProfile
