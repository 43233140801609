import MsgIsSentPending from '@/assets/icons/msg-is-pending.svg'
import MsgIsSentError from '@/assets/icons/msg-is-error.svg'
import MsgIsSentIcon from '@/assets/icons/msg-is-sent.svg'
import MsgIsReadIcon from '@/assets/icons/msg-is-read.svg'
import PropTypes from 'prop-types'

export default function MessageStatus(props) {
  const { status } = props

  switch (status) {
    case 'sending':
      return (
        <MsgIsSentPending
          viewBox="0 0 14 14"
          style={{ width: 16, height: 14, transform: 'translate(0, 1px)', stroke: 'currentColor' }}
        />
      )
    case 'sendFailed':
      return <MsgIsSentError viewBox="0 0 14 14" style={{ width: 16, height: 12, transform: 'translate(0, 1px)' }} />
    case 'isSend':
      return <MsgIsSentIcon viewBox="0 0 12 10" style={{ width: 16, height: 9 }} />
    case 'isRead':
      return <MsgIsReadIcon viewBox="0 0 12 10" style={{ width: 16, height: 9 }} />
    default:
      return <MsgIsSentIcon viewBox="0 0 12 10" style={{ width: 16, height: 9 }} />
  }
}

MessageStatus.propTypes = {
  status: PropTypes.oneOf(['sending', 'sendFailed', 'isSend', 'isRead']),
}
