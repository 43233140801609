import { getRandomTopic } from '@/services/chat'
import React, { useEffect, useState } from 'react'
import './SystemMsg.css'

export default function SystemMsg(props) {
  const { topic } = props
  // const [topic, setTopic] = useState('')

  // useEffect(() => {
  //   async function fetchData() {
  //     const { data, code } = await getRandomTopic()
  //     if (code !== 200) return
  //     setTopic(data)
  //   }
  //   fetchData()
  // }, [])

  return (
    <div className="topic-wrapper">
      <span className="topic">{topic}</span>
    </div>
  )
}
