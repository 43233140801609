import PropTypes from 'prop-types'
import './DayMeta.css'

function DayMeta(props) {
  const { date } = props

  const showYear = new Date(date).getFullYear() !== new Date().getFullYear()
  const options = showYear
    ? {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }
    : {
        day: 'numeric',
        month: 'long',
      }

  let timeArea = 'zh'

  function isToday() {
    const date1 = new Date()
    const date2 = new Date(date)
    if (
      date1.getFullYear() !== date2.getFullYear() ||
      date1.getMonth() !== date2.getMonth() ||
      date1.getDate() !== date2.getDate()
    ) {
      return false
    } else {
      return true
    }
  }

  return (
    <div className="day-meta">
      <div className="day-meta-wrapper">
        {isToday() && <div> 今天 </div>}
        {!isToday() && <div>{new Date(date).toLocaleDateString([timeArea], options)}</div>}
      </div>
    </div>
  )
}

DayMeta.propTypes = {
  date: PropTypes.number.isRequired,
}

export default DayMeta
