import classNames from 'classnames'
import MessageMeta from './MessageMeta'
import DayMeta from './DayMeta'
import { Avatar, Card, Dialog, Image, Button, DotLoading } from 'antd-mobile'
import SystemMsg from '@/components/message/SystemMsg'
import { ActionSheet } from 'antd-mobile'
import { useEffect, useRef } from 'react'
import './Message.css'
import { getUserBaseInfoApi } from '@/services/user'
import UserProfile from '../user-profile/UserProfile'
import { useNavigate } from 'react-router-dom'
import cookie from 'react-cookies'

export default function Message(props) {
  const {
    messageId,
    id,
    updateUserInfoCache,
    currChat,
    uid,
    msgType,
    msgContent,
    msgMeta,
    msgStatus,
    ts,
    isOutgoing,
    showDate,
    showSpace,
    handleShowImage,
    currUid,
    handleMsgOpt,
    isGroupChat,
    userMap,
    isShowUser,
    handleSetMsgMeta,
  } = props
  const is_debug = cookie.load('is_debug')
  const navigate = useNavigate()

  const longClickRef = useRef({ t: null, longClick: 0, message: {} })

  // 展示色子摇晃的过程
  useEffect(() => {
    if (msgType === 'dice') {
      setTimeout(() => {
        const node = document.querySelector('.dice-' + messageId)
        if (node && node.querySelector('img')) {
          node.querySelector('img').src = `/img/dice/${msgContent}.jpg`
        }
      }, 800)
    }
  }, [])

  function gtouchstart(message) {
    return (event) => {
      longClickRef.current.longClick = 0
      longClickRef.current.message = {}
      longClickRef.current.t = setTimeout(function () {
        longClickRef.current.longClick = 1
        longClickRef.current.message = message
      }, 300)
    }
  }

  function showMenus(message) {
    let actions = [{ text: '撤回', key: 'delete' }]
    if (message.msgType === 'text' || message.msgType === 'group_invite') {
      actions = [
        { text: '复制', key: 'copy' },
        { text: '撤回', key: 'delete' },
      ]
    }
    if (!isGroupChat || currChat.uid !== currUid) {
      if (uid !== currUid) {
        actions = [{ text: '复制', key: 'copy' }]
      }
    }
    console.log(actions)
    ActionSheet.show({
      actions: actions,
      onAction: handleMsgOpt(message, isGroupChat),
      closeOnAction: true,
      closeOnMaskClick: true,
    })
  }

  function gtouchend(messageId) {
    return (event) => {
      if (longClickRef.current.longClick) {
        const message = longClickRef.current.message
        showMenus(message)
      }

      if (longClickRef.current.t) {
        clearTimeout(longClickRef.current.t)
        longClickRef.current.t = 0
      }
    }
  }

  function gtouchmove(messageId) {
    return () => {
      if (longClickRef.current.t) {
        clearTimeout(longClickRef.current.t)
        longClickRef.current.t = 0
      }
    }
  }

  function handleCilckImage(id) {
    return () => {
      if (!longClickRef.current.longClick) {
        handleShowImage(id)()
      }
    }
  }

  function handleRightClick(event) {
    event.preventDefault()
    if (!longClickRef.current.longClick) {
      showMenus(props)
    }
  }

  async function handleShowUserInfo() {
    const { data, code } = await getUserBaseInfoApi(uid, 'handleShowUserInfo')
    if (code !== 200) return
    Dialog.show({
      closeOnMaskClick: true,
      content: <UserProfile user={data} chatid={null} updateUserInfoCache={updateUserInfoCache} showOpt={false} />,
    })
  }

  function handleJoinGroup(msgMeta) {
    return async () => {
      navigate(`/chat?join_group=${msgMeta.token}`)
      window.location.reload()
    }
  }

  function handleRecvFile(isAgree) {
    return () => {
      handleSetMsgMeta(messageId, { agree: isAgree })
    }
  }

  function getImageVideo() {
    if (is_debug && msgMeta) {
      msgMeta.agree = true
    }
    const mt = msgType === 'image' ? '图片' : '视频'
    if (msgType === 'image' || msgType === 'video') {
      if (msgMeta.agree == undefined) {
        if (isOutgoing) {
          return (
            <div
              className={classNames('message-content message-image', {
                'message-bubble-out': isOutgoing,
              })}
              style={{ display: 'block', padding: '10px 10px 30px 16px', borderRadius: 10 }}>
              <div style={{ textAlign: 'center' }}>
                <span>
                  等待对方确认是否接收{mt} <DotLoading />
                </span>
              </div>
              <MessageMeta
                isGroupChat={isGroupChat}
                status={msgStatus}
                date={ts}
                className="meta-text meta-image"
                isOutgoing={isOutgoing}
              />
            </div>
          )
        } else {
          return (
            <div
              className={classNames('message-content message-image', {
                'message-bubble-out': isOutgoing,
              })}
              style={{ display: 'block', padding: '20px 30px', borderRadius: 10 }}>
              <div style={{ textAlign: 'center', paddingBottom: 20 }}>
                <span style={{ fontSize: 16 }}>是否接收对方发送的{mt} </span>
              </div>
              <div>
                <Button fill="outline" style={{ marginRight: '20px' }} color="danger" onClick={handleRecvFile(false)}>
                  不接收
                </Button>
                <Button fill="outline" color="primary" onClick={handleRecvFile(true)}>
                  接收
                </Button>
              </div>
              <MessageMeta
                isGroupChat={isGroupChat}
                status={msgStatus}
                date={ts}
                className="meta-text meta-image"
                isOutgoing={isOutgoing}
              />
            </div>
          )
        }
      }
      if (!msgMeta.agree) {
        if (isOutgoing) {
          return (
            <div
              className={classNames('message-content message-image', {
                'message-bubble-out': isOutgoing,
              })}
              style={{ display: 'block', padding: '10px 10px 30px 16px', borderRadius: 10 }}>
              <div style={{ textAlign: 'center' }}>
                <span>对方已拒绝接收你发的{mt}</span>
              </div>
              <MessageMeta
                isGroupChat={isGroupChat}
                status={msgStatus}
                date={ts}
                className="meta-text meta-image"
                isOutgoing={isOutgoing}
              />
            </div>
          )
        } else {
          return (
            <div
              className={classNames('message-content message-image', {
                'message-bubble-out': isOutgoing,
              })}
              style={{ display: 'block', padding: '10px 16px 30px 10px', borderRadius: 10 }}>
              <div style={{ textAlign: 'center' }}>
                <span>已拒绝接收对方发的{mt}</span>
              </div>
              <MessageMeta
                isGroupChat={isGroupChat}
                status={msgStatus}
                date={ts}
                className="meta-text meta-image"
                isOutgoing={isOutgoing}
              />
            </div>
          )
        }
      } else {
        if (msgType === 'image') {
          return (
            <div
              className={classNames('message-content message-image', {
                'message-bubble-out': isOutgoing,
              })}
              onClick={handleCilckImage(id)}>
              <div>
                {
                  <Image
                    alt="img"
                    lazy
                    width={(msgMeta && msgMeta.width) || 200}
                    height={(msgMeta && msgMeta.height) || 200}
                    src={msgContent.replace('http:', '')}
                  />
                }
              </div>
              <MessageMeta
                isGroupChat={isGroupChat}
                status={msgStatus}
                date={ts}
                className="meta-text meta-image"
                isOutgoing={isOutgoing}
              />
            </div>
          )
        } else if (msgType === 'video') {
          return (
            <div
              className={classNames('message-content message-image', {
                'message-bubble-out': isOutgoing,
              })}>
              <div>
                <video
                  width={(msgMeta && msgMeta.width) || '100%'}
                  height={(msgMeta && msgMeta.height) || '300px'}
                  src={msgContent.replace('http:', '')}
                  controls="controls"></video>
              </div>
              <MessageMeta
                isGroupChat={isGroupChat}
                status={msgStatus}
                date={ts}
                className="meta-text meta-image"
                isOutgoing={isOutgoing}
              />
            </div>
          )
        }
      }
    } else if (msgType === 'video') {
    } else {
      return ''
    }
  }

  if (msgType === 'system') return <SystemMsg topic={msgContent} />
  else
    return (
      <>
        {showDate && <DayMeta date={ts}></DayMeta>}
        <div
          className={classNames('message', {
            'message-out': isOutgoing,
            'show-space': showSpace,
          })}
          msgid={id}>
          {isGroupChat && (
            <div
              style={{ marginTop: isShowUser ? '10px' : '0px', visibility: isShowUser ? 'visible' : 'hidden' }}
              onClick={handleShowUserInfo}>
              <Avatar src={userMap[uid]?.avatar} style={{ '--size': '36px' }} />
            </div>
          )}
          <div style={{ textAlign: isOutgoing ? 'right' : 'left' }}>
            {isGroupChat && (
              <span
                style={{
                  fontSize: '12px',
                  color: '#5f5f5f',
                  margin: '8px 6px 0px 6px',
                  display: isShowUser ? 'block' : 'none',
                }}>
                {userMap[uid] ? userMap[uid].remark_name || userMap[uid].nickname || '未知用户' : '未知用户'}
              </span>
            )}
            <div
              className="message-body"
              onTouchStart={gtouchstart(props)}
              onTouchMove={gtouchmove(id)}
              onTouchEnd={gtouchend(id)}
              onContextMenu={handleRightClick}>
              <div className={classNames('message-wrapper', { 'message-out': isOutgoing })}>
                {msgType === 'text' && (
                  <div
                    className={classNames('message-content message-bubble', {
                      'message-bubble-out': isOutgoing,
                    })}>
                    <div>
                      <div className="message-text">{msgContent}</div>
                      <MessageMeta
                        isGroupChat={isGroupChat}
                        status={msgStatus}
                        date={ts}
                        className="meta-hidden"
                        isOutgoing={isOutgoing}
                      />
                    </div>
                    <MessageMeta
                      isGroupChat={isGroupChat}
                      status={msgStatus}
                      date={ts}
                      className="meta-text"
                      isOutgoing={isOutgoing}
                    />
                  </div>
                )}

                {getImageVideo()}

                {msgType === 'audio' && (
                  <div
                    className={classNames('message-content', 'message-audio', {
                      'message-bubble-out': isOutgoing,
                    })}>
                    <div className="message-audio-wrapper">
                      <audio controls controlsList="nodownload" preload="none">
                        <source src={msgContent} />
                        <source src={msgContent.split('.')[0] + '.mp3'} type="audio/mpeg" />
                      </audio>
                    </div>
                    <MessageMeta
                      isGroupChat={isGroupChat}
                      status={msgStatus}
                      date={ts}
                      className="meta-text meta-image"
                      isOutgoing={isOutgoing}
                    />
                  </div>
                )}

                {msgType === 'group_invite' && (
                  <div
                    style={{ background: 'white' }}
                    className={classNames('message-content message-image', {
                      'message-bubble-out': isOutgoing,
                    })}>
                    <div style={{ cursor: 'pointer' }}>
                      <Card
                        onClick={handleJoinGroup(msgMeta)}
                        title={
                          <div>
                            <span style={{ color: '#1677ff' }}>群聊邀请 </span>
                            <span style={{ fontSize: '10px', fontWeight: 'normal' }}>【点击消息查看群聊】</span>
                          </div>
                        }
                        bodyStyle={{ padding: 0 }}
                        style={{ minWidth: '200px', maxWidth: '300px' }}>
                        <div style={{ marginTop: '6px' }}>群聊名称：{msgMeta.title}</div>
                        <div style={{ marginTop: '6px' }}>口令：{msgMeta.password}</div>
                      </Card>

                      <MessageMeta
                        isGroupChat={isGroupChat}
                        status={msgStatus}
                        date={ts}
                        className="meta-hidden"
                        isOutgoing={isOutgoing}
                      />
                    </div>
                    <MessageMeta
                      isGroupChat={isGroupChat}
                      status={msgStatus}
                      date={ts}
                      className="meta-text meta-image"
                      isOutgoing={isOutgoing}
                    />
                  </div>
                )}

                {msgType === 'dice' && (
                  <div
                    className={classNames('message-content message-image', {
                      'message-bubble-out': isOutgoing,
                    })}>
                    <div>
                      {
                        <Image
                          alt="img"
                          className={'dice-' + messageId}
                          width={80}
                          height={80}
                          msgContent
                          src={`/img/dice/z.jpg`}
                        />
                      }
                    </div>
                    <MessageMeta
                      isGroupChat={isGroupChat}
                      status={msgStatus}
                      date={ts}
                      className="meta-text meta-image"
                      isOutgoing={isOutgoing}
                    />
                  </div>
                )}

                <div className="message-tile-padding" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
}
